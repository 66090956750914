@import url("https://use.typekit.net/kcs0pxg.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ProseMirror {
  background-color: #FFFFFF !important;
  padding: 30px !important;
}

/* Table-specific styling */
.ProseMirror table {
  margin: 32px 0px 0px 0px;
  background-color: #fafafa;
  font-family: Tondo;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.2;
  letter-spacing: 0.01071em;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

.ProseMirror table td,
.ProseMirror table th {
  min-width: 1em;
  border: 1px solid rgb(224, 224, 224);
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  vertical-align: center;
  padding: 16px;
}


.ProseMirror table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror h4 {
  font-family: "Mundial";
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 0.95;
  color: #5158F5;
  margin: 32px 0px 0px 0px;
}

.ProseMirror h5 {
  font-family: "Mundial";
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.3;
  color: #020D3E;
  margin: 32px 0px 0px 0px;
}

.ProseMirror h6 {
  font-family: "Mundial";
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 0.95;
  color: #020D3E;
  margin: 32px 0px 0px 0px;
}

.ProseMirror p {
  font-family: "Tondo";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2;
  color: #020D3E;
  margin: 12px 0px 0px 0px !important;
}

.ProseMirror ul {
  list-style: none;
}

.ProseMirror li p {
  font-size: 14px;
  margin: 16px 0px 0px 0px !important;
}